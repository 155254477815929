


















import { Component, Vue, Ref } from 'vue-property-decorator'
import NavBar from '@/components/index/NavBar.vue'
import FooterBar from '@/components/index/FooterBar.vue'
import { debounce } from 'lodash'

@Component({
  components: {
    NavBar,
    FooterBar
  }
})
export default class HomeMain extends Vue {
  @Ref('navbar') readonly navbar!: NavBar
  private showFixedHeader: boolean = false
  private observer!: IntersectionObserver
  private windowResizeHandler!: any

  /**
   * 页面滚动的时候显示固定导航菜单
   */
  private addFixedHeaderObserver () {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0
    }
    this.observer = new IntersectionObserver((entries: IntersectionObserverEntry[]) => {
      this.showFixedHeader = !entries[0].isIntersecting
    }, options)
    // @ts-ignore
    if (this.navbar.$el.offsetHeight) {
      this.observer.observe(this.navbar.$el)
    }
  }

  /**
   * 移除固定导航菜单监听
   */
  private removeFixedHeaderObserver () {
    this.observer && this.observer.disconnect()
  }

  mounted () {
    this.addFixedHeaderObserver()
    // 窗口大小调整的时候更新当前窗口大小
    this.windowResizeHandler = debounce(() => {
      this.$store.commit('updateWindowWidth', window.innerWidth)
    }, 500)
    window.addEventListener('resize', this.windowResizeHandler)
  }

  destroyed () {
    this.removeFixedHeaderObserver()
    window.removeEventListener('resize', this.windowResizeHandler)
  }
}
