







import { Component, Vue } from 'vue-property-decorator'
import ThemeSwitcher from '@/components/common/ThemeSwitcher.vue'
import BrowserUpdatePrompt from '@/components/common/BrowserUpdatePrompt.vue'

@Component({
  components: {
    ThemeSwitcher,
    BrowserUpdatePrompt
  }
})
export default class IndexApp extends Vue {}
