




































import { Component, Vue } from 'vue-property-decorator'

@Component
export default class FooterBar extends Vue {
  private currentYear: number = new Date().getFullYear()
}
