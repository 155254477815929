
















































































import { Component, Vue } from 'vue-property-decorator'
import LangSwitcher from '@/components/common/LangSwitcher.vue'

@Component({
  components: {
    LangSwitcher
  }
})
export default class NavBar extends Vue {
  private lang: string = ''
  get loginLink () {
    return `/home/${this.lang}/login`
  }
  get newsLink () {
    return `/${this.lang}/news.html`
  }
  get paperLink () {
    return `/${this.lang}/paper.html`
  }
  get registerLink () {
    return `/home/${this.lang}/register`
  }
  get logoImgSrc () {
    // @ts-ignore
    if (this.$parent.showFixedHeader) {
      return require('@/assets/logos/idrug-logo-white.png')
    }
    return require('@/assets/logos/idrug-logo-blue.png')
  }
  get guideLink () {
    return `/api/download/sample/-1/user_guide.pdf`
    // https://test.gene.woa.com/cn
    // https://gene.ai.tencent.com/cn
  }
}
